import React from 'react';
import MenuBar from '../components/MenuBar.jsx';
import BKMenuBanner from '../components/BKMenuBanner.jsx';
import TopFood from '../components/TopFood.jsx';
import FlameGrilledBurger from '../components/FlameGrilledBurger.jsx';
import SaveMoneySectionV2 from '../components/SaveMoneySectionV2.jsx';
import Footer from '../components/Footer.jsx';
import './BurgerkinghomepageAI.css';

const BurgerkinghomepageAI = () => {
  return (
    <div className='BurgerkinghomepageAI-container'>
      <MenuBar 
        param2538771='Order'
        param2538774='Offers'
        param2538777='Menu'
        param2538780='Delivery'
        param2538783='More'
        bgparam2538784='http://localhost:5085/api/project/image/BurgerKingREACT/1732817639969670.png'
        param2538792='For item availability'
        param2538794='Choose your location'
        param2538800='0.00'
      />
      <BKMenuBanner 
        bgparam2538803='http://localhost:5085/api/project/image/BurgerKingREACT/1732817641433958.png'
        param2538804='Welcome You can earn points with every purchase and use them to redeem rewards.'
      />
      <div className='BurgerkinghomepageAI-banner'>
        <div className='BurgerkinghomepageAI-banner-row'>
          <div className='BurgerkinghomepageAI-topfood'>
            <TopFood 
              bgparam2538573='http://localhost:5085/api/project/image/BurgerKingREACT/1732817642941143.png'
              param2538575='Gourmet Kings Smoked Cheddar Bacon collection'
              param2538576='Discover juicy Aberdeen Angus, or tuck into tender crispy chicken made with 100% chicken breast'
            />
          </div>
          <div className='BurgerkinghomepageAI-topfood'>
            <TopFood 
              bgparam2538573='http://localhost:5085/api/project/image/BurgerKingREACT/1732817644514590.png'
              param2538575='Doritos x Burger King'
              param2538576='We have brought the heat back. Get your hands on this tasty collaboration.'
            />
          </div>
        </div>
        <FlameGrilledBurger 
          param2547617='FLAME-GRILLED BURGERS (18)'
        />
        <SaveMoneySectionV2 
          bgparam2569576='http://localhost:5085/api/project/image/BurgerKingREACT/1732817650763478.png'
          param2569578='Save '
          param2569580='With offers on demand'
          bgparam2569582='http://localhost:5085/api/project/image/BurgerKingREACT/1732817651297794.png'
          bgparam2569583='http://localhost:5085/api/project/image/BurgerKingREACT/1732817651722702.png'
          param2569584='Apple and the Apple logo are trademarks of Apple Inc., registered in the U.S. and other countries. App Store is a service mark of Apple Inc. Google Play is a trademark of Google Inc. Terms apply.'
        />
      </div>
      <Footer 
        param2538978='BK INFO'
        param2538980='About BK '
        param2538982='FAQs'
        param2538984='Policies'
        param2538986='Get in touch'
        param2538988='Terms  Conditions'
        param2538990='Guest Trac'
        param2538992='Trademarks'
        param2538994='Allergens'
        param2538995='BK AND YOU'
        param2538997='Careers'
        param2538999='Privacy Policy'
        param25381001='Modern Slavery Statement'
        param25381003='Tax Strategy'
        param25381020='TM  Copyright 2024 Burger King Corporation. All Rights Reserved.'
        param25381011='en'
      />
    </div>
  );
};

export default BurgerkinghomepageAI;
