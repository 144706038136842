import React from 'react';
import PropTypes from 'prop-types';
import './FoodMenuItems.css';

const FoodMenuItems = ({
  bgparam25381381 = 'http://localhost:5085/api/project/image/BurgerKingREACT/1732817511943670.png',
  param2538939 = 'WHOPPER',
  param2538940 = '601.1 kcal / 2,515.1 kJ',
  param2538941 = 'Contains: wheat, sesame, celery, gluten',
  param2538942 = 'May contain: milk, eggs',
  param2538943 = 'May contain via shared equipment: soy'
}) => {
  return (
    <div className='FoodMenuItems-container'>
      <div className='FoodMenuItems-image' style={{ backgroundImage: `url(${bgparam25381381})` }}></div>
      <div className='FoodMenuItems-info'>
        <div className='FoodMenuItems-title'>
          <span>{param2538939}</span>
        </div>
        <span>{param2538940}</span>
        <span>{param2538941}</span>
        <span>{param2538942}</span>
        <span>{param2538943}</span>
      </div>
    </div>
  );
};

FoodMenuItems.propTypes = {
  bgparam25381381: PropTypes.string,
  param2538939: PropTypes.string,
  param2538940: PropTypes.string,
  param2538941: PropTypes.string,
  param2538942: PropTypes.string,
  param2538943: PropTypes.string
};

export default FoodMenuItems;