import logo from './logo.svg';
import './App.css';
import './commoncss/ButtonCSSStyle.css';


import BurgerkinghomepageAI from './pages/BurgerkinghomepageAI'; 
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';

function App() {
  return (
    <div className="App">
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<BurgerkinghomepageAI />} /> 

        </Routes>
      </div>
    </Router>
  </div>

  );
}

export default App;
