import React from 'react';
import PropTypes from 'prop-types';
import './TopFood.css';

const TopFood = ({
  bgparam2538573 = 'http://localhost:5085/api/project/image/BurgerKingREACT/1732817484977950.png',
  param2538575 = 'Gourmet Kings Smoked Cheddar \nBacon collection',
  param2538576 = 'Discover juicy Aberdeen Angus, or tuck into\ntender crispy chicken made with 100 chicken\nbreast',
}) => {
  return (
    <div className="TopFood-container">
      <div
        className="TopFood-image"
        style={{ backgroundImage: `url(${bgparam2538573})` }}
      />
      <div className="TopFood-content">
        <div className="TopFood-text">
          <span className="TopFood-title">{param2538575}</span>
          <span className="TopFood-description">{param2538576}</span>
        </div>
        <div className="TopFood-action">
          <button className="Buttontypethree">Discover Gourmet Kings</button>
        </div>
      </div>
    </div>
  );
};

TopFood.propTypes = {
  bgparam2538573: PropTypes.string,
  param2538575: PropTypes.string,
  param2538576: PropTypes.string,
};

export default TopFood;
