import React from 'react';
import PropTypes from 'prop-types';
import './MenuBar.css';

const MenuBar = ({
  param2538771 = 'Order',
  param2538774 = 'Offers',
  param2538777 = 'Menu',
  param2538780 = 'Delivery',
  param2538783 = 'More',
  bgparam2538784 = 'http://localhost:5085/api/project/image/BurgerKingREACT/1732817432982535.png',
  param2538792 = 'For item availability',
  param2538794 = 'Choose your location',
  param2538800 = '0.00'
}) => {
  return (
    <div className="MenuBar-container">
      <div className="MenuBar-nav">
        <div className="MenuBar-logo">
          <img src="http://localhost:5085/api/project/image/BurgerKingREACT/1732817432460186.png" alt="logo" />
        </div>

        <div className="MenuBar-item">
          <span>{param2538771}</span>
        </div>
        <div className="MenuBar-item">
          <span>{param2538774}</span>
        </div>
        <div className="MenuBar-item">
          <span>{param2538777}</span>
        </div>
        <div className="MenuBar-item">
          <span>{param2538780}</span>
        </div>
        <div className="MenuBar-item">
          <span>{param2538783}</span>
        </div>
      </div>

      <div className="MenuBar-image" style={{ backgroundImage: `url(${bgparam2538784})` }}></div>

      <div className="MenuBar-right">
        <div className="MenuBar-info">
          <div className="MenuBar-info-section">
            <img src="http://localhost:5085/api/project/image/BurgerKingREACT/1732817433444226.png" alt="information" />
          </div>
          <div className="MenuBar-info-text">
            <span>{param2538792}</span>
          </div>
          <div className="MenuBar-info-text">
            <span>{param2538794}</span>
          </div>
        </div>
        <button className="Buttontypetwo">Sign Up</button>
        <div className="MenuBar-cart">
          <div className="MenuBar-cart-icon">
            <img src="http://localhost:5085/api/project/image/BurgerKingREACT/1732817433956081.png" alt="cart" />
          </div>
          <span>{param2538800}</span>
        </div>
      </div>
    </div>
  );
};

MenuBar.propTypes = {
  param2538771: PropTypes.string,
  param2538774: PropTypes.string,
  param2538777: PropTypes.string,
  param2538780: PropTypes.string,
  param2538783: PropTypes.string,
  bgparam2538784: PropTypes.string,
  param2538792: PropTypes.string,
  param2538794: PropTypes.string,
  param2538800: PropTypes.string,
};

export default MenuBar;
