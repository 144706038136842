import React from 'react';
import PropTypes from 'prop-types';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Footer.css';

const Footer = ({
  param2538978 = 'BK INFO',
  param2538980 = 'About BK ',
  param2538982 = 'FAQs',
  param2538984 = 'Policies',
  param2538986 = 'Get in touch',
  param2538988 = 'Terms  Conditions',
  param2538990 = 'Guest Trac',
  param2538992 = 'Trademarks',
  param2538994 = 'Allergens',
  param2538995 = 'BK AND YOU',
  param2538997 = 'Careers',
  param2538999 = 'Privacy Policy',
  param25381001 = 'Modern Slavery Statement',
  param25381003 = 'Tax Strategy',
  param25381020 = 'TM  Copyright 2024 Burger King Corporation. All Rights Reserved.',
  param25381011 = 'en'
}) => {
  return (
    <div className="footer-container">
      <div className="footer-upper">
        <div className="footer-column">
          <div className="footer-header">
            <span>{param2538978}</span>
          </div>
          <div className="footer-item">
            <span>{param2538980}</span>
          </div>
          <div className="footer-item">
            <span>{param2538982}</span>
          </div>
          <div className="footer-item">
            <span>{param2538984}</span>
          </div>
          <div className="footer-item">
            <span>{param2538986}</span>
          </div>
          <div className="footer-item">
            <span>{param2538988}</span>
          </div>
          <div className="footer-item">
            <span>{param2538990}</span>
          </div>
          <div className="footer-item">
            <span>{param2538992}</span>
          </div>
          <div className="footer-item">
            <span>{param2538994}</span>
          </div>
        </div>
        <div className="footer-column">
          <div className="footer-header">
            <span>{param2538995}</span>
          </div>
          <div className="footer-item">
            <span>{param2538997}</span>
          </div>
          <div className="footer-item">
            <span>{param2538999}</span>
          </div>
          <div className="footer-item">
            <span>{param25381001}</span>
          </div>
          <div className="footer-item">
            <span>{param25381003}</span>
          </div>
        </div>
      </div>
      <div className="footer-lower">
        <div className="footer-lower-left">
          <img src="http://localhost:5085/api/project/image/BurgerKingREACT/1732817602528855.png" alt="Logo" className="footer-logo" />
          <span>{param25381020}</span>
        </div>
        <div className="footer-lower-right">
          <div className="footer-lang">
            <img src="http://localhost:5085/api/project/image/BurgerKingREACT/1732817602992843.png" alt="Language" className="footer-icon" />
            <span>{param25381011}</span>
          </div>
          <img src="http://localhost:5085/api/project/image/BurgerKingREACT/1732817603411166.png" alt="Icon1" className="footer-icon" />
          <img src="http://localhost:5085/api/project/image/BurgerKingREACT/1732817603812172.png" alt="Icon2" className="footer-icon" />
          <img src="http://localhost:5085/api/project/image/BurgerKingREACT/1732817604212797.png" alt="Icon3" className="footer-icon" />
          <img src="http://localhost:5085/api/project/image/BurgerKingREACT/1732817604612270.png" alt="Icon4" className="footer-icon" />
        </div>
      </div>
    </div>
  );
};

Footer.propTypes = {
  param2538978: PropTypes.string,
  param2538980: PropTypes.string,
  param2538982: PropTypes.string,
  param2538984: PropTypes.string,
  param2538986: PropTypes.string,
  param2538988: PropTypes.string,
  param2538990: PropTypes.string,
  param2538992: PropTypes.string,
  param2538994: PropTypes.string,
  param2538995: PropTypes.string,
  param2538997: PropTypes.string,
  param2538999: PropTypes.string,
  param25381001: PropTypes.string,
  param25381003: PropTypes.string,
  param25381020: PropTypes.string,
  param25381011: PropTypes.string
};

export default Footer;
