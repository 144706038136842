import React from 'react';
import PropTypes from 'prop-types';
import './SaveMoneySectionV2.css';

const SaveMoneySectionV2 = ({
  bgparam2569576 = 'http://localhost:5085/api/project/image/BurgerKingREACT/1732817565317779.png',
  param2569578 = 'Save ',
  param2569580 = 'With offers on\ndemand',
  bgparam2569582 = 'http://localhost:5085/api/project/image/BurgerKingREACT/1732817565852523.png',
  bgparam2569583 = 'http://localhost:5085/api/project/image/BurgerKingREACT/1732817566261363.png',
  param2569584 = 'Apple and the Apple logo are trademarks of Apple Inc., registered in the U.S.\nand other countries. App Store is a service mark of Apple Inc. Google Play is a\ntrademark of Google Inc. Terms apply.'
}) => {
  return (
    <div className="SaveMoneySectionV2-container">
      <div className="SaveMoneySectionV2-image" style={{ backgroundImage: `url(${bgparam2569576})` }}></div>
      <div className="SaveMoneySectionV2-content">
        <span className="SaveMoneySectionV2-title">{param2569578}</span>
        <div className="SaveMoneySectionV2-subtitle">
          <span className="SaveMoneySectionV2-highlight">{param2569580}</span>
        </div>
        <div className="SaveMoneySectionV2-logos">
          <div className="SaveMoneySectionV2-logo1" style={{ backgroundImage: `url(${bgparam2569582})` }}></div>
          <div className="SaveMoneySectionV2-logo2" style={{ backgroundImage: `url(${bgparam2569583})` }}></div>
        </div>
        <span className="SaveMoneySectionV2-disclaimer">{param2569584}</span>
      </div>
    </div>
  );
};

SaveMoneySectionV2.propTypes = {
  bgparam2569576: PropTypes.string,
  param2569578: PropTypes.string,
  param2569580: PropTypes.string,
  bgparam2569582: PropTypes.string,
  bgparam2569583: PropTypes.string,
  param2569584: PropTypes.string
};

export default SaveMoneySectionV2;
