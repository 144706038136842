import React from 'react';
import PropTypes from 'prop-types';
import FoodMenuItems from '../components/FoodMenuItems.jsx';
import './FlameGrilledBurger.css';

const FlameGrilledBurger = ({ param2547617 = 'FLAME-GRILLED BURGERS (18)' }) => {
  return (
    <div className='flameGrilledBurger-container'>
      <div className='flameGrilledBurger-header'>
        <span>{param2547617}</span>
      </div>
      <div className='flameGrilledBurger-row'>
        <FoodMenuItems 
          bgparam25381381='http://localhost:5085/api/project/image/BurgerKingREACT/1732817530887209.png'
          param2538939='WHOPPER'
          param2538940='601.1 kcal / 2,515.1 kJ'
          param2538941='Contains: wheat, sesame, celery, gluten'
          param2538942='May contain: milk, eggs'
          param2538943='May contain via shared equipment: soy'
        />
        <FoodMenuItems 
          bgparam25381381='http://localhost:5085/api/project/image/BurgerKingREACT/1732817531609596.png'
          param2538939='Double WHOPPER'
          param2538940='840 kcal / 3,514.4 kJ'
          param2538941='Contains: wheat, sesame, celery, gluten'
          param2538942='May contain: milk, eggs'
          param2538943='May contain via shared equipment: soy'
        />
        <FoodMenuItems 
          bgparam25381381='http://localhost:5085/api/project/image/BurgerKingREACT/1732817532216707.png'
          param2538939='Bacon Double Cheese XL'
          param2538940='914.1 kcal / 3,824.4 kJ'
          param2538941='Contains: milk, wheat, sesame, gluten'
          param2538942='May contain: eggs'
          param2538943='May contain via shared equipment: soy'
        />
        <FoodMenuItems 
          bgparam25381381='http://localhost:5085/api/project/image/BurgerKingREACT/1732817532870895.png'
          param2538939='BBQ Steakhouse Angus'
          param2538940='780.1 kcal / 3,263.8 kJ'
          param2538941='Contains: milk, wheat, mustard, gluten'
          param2538942='May contain: eggs'
          param2538943='May contain via shared equipment: soy'
        />
      </div>
      <div className='flameGrilledBurger-grid'>
        <FoodMenuItems 
          bgparam25381381='http://localhost:5085/api/project/image/BurgerKingREACT/1732817533566378.png'
          param2538939='Smoked Cheddar  Bacon Angus'
          param2538940='765.9 kcal / 3,204.3 kJ'
          param2538941='Contains: milk, eggs, wheat, mustard, gluten'
          param2538942='May contain: sesame'
          param2538943='May contain via shared equipment: soy'
        />
        <FoodMenuItems 
          bgparam25381381='http://localhost:5085/api/project/image/BurgerKingREACT/1732817534160338.png'
          param2538939='Bacon Double Cheeseburger'
          param2538940='441.7 kcal / 1,848.2 kJ'
          param2538941='Contains: milk, wheat, sesame, gluten'
          param2538942='May contain: eggs'
          param2538943='May contain via shared equipment: soy'
        />
        <FoodMenuItems 
          bgparam25381381='http://localhost:5085/api/project/image/BurgerKingREACT/1732817534736056.png'
          param2538939='Bacon King'
          param2538940='729.5 kcal / 3,052.1 kJ'
          param2538941='Contains: milk, wheat, sesame, celery, gluten'
          param2538942='May contain: eggs'
          param2538943='May contain via shared equipment: soy'
        />
        <FoodMenuItems 
          bgparam25381381='http://localhost:5085/api/project/image/BurgerKingREACT/1732817535366612.png'
          param2538939='Bacon King Double'
          param2538940='1,042.8 kcal / 4,363 kJ'
          param2538941='Contains: milk, wheat, sesame, celery, gluten'
          param2538942='May contain: eggs'
          param2538943='May contain via shared equipment: soy'
        />
      </div>
    </div>
  );
};

FlameGrilledBurger.propTypes = {
  param2547617: PropTypes.string,
};

export default FlameGrilledBurger;